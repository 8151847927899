import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"

export const Content = () => {
	const { t } = useTranslation()

	return (
		<div className="flex flex-col gap-12">
			<div className="flex flex-col gap-8">
				<h1 className="text-4xl md:text-5xl lg:text-[68px]">
					{t("security-report-page.heading")}
				</h1>
				<p className="sm:text-lg lg:text-xl">
					{t("security-report-page.desc")}
				</p>
			</div>
		</div>
	)
}
