import React, {
	ChangeEvent,
	Dispatch,
	FormEvent,
	SetStateAction,
	useState,
} from "react"
import { Input, PrimaryBtn } from "components/library"
import { EMAIL_PATTERN } from "utils/validation"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { APIResponse } from "types/api"

const API_URL = `${process.env.GATSBY_BACKEND_API_V1}/request_security_report`

const initialData = {
	fname: "",
	lname: "",
	company: "",
	email: "",
	job_title: "",
}

type P = {
	setError: Dispatch<SetStateAction<string | null>>
	setModal: Dispatch<SetStateAction<boolean>>
	setMessage: Dispatch<SetStateAction<string>>
}

export const Form = ({ setError, setModal, setMessage }: P) => {
	const [data, setData] = useState(initialData)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [isEmailError, setIsEmailError] = useState(false)
	const { t } = useTranslation()

	function handleDataChange(
		e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
	) {
		const { name, value } = e.target

		if (name === "email") setIsEmailError(false)

		setData({
			...data,
			[name]: value,
		})
	}

	async function handleSubmit(e: FormEvent<HTMLFormElement>) {
		e.preventDefault()
		if (!EMAIL_PATTERN.test(data.email)) return setIsEmailError(true)

		setIsSubmitting(true)

		const body = JSON.stringify({
			first_name: data.fname,
			last_name: data.lname,
			organization: data.company,
			email: data.email,
			job_title: data.job_title,
		})

		try {
			const response: APIResponse = await (
				await fetch(API_URL, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body,
				})
			).json()
			if (response.result.status === "Success") {
				if (
					response.result.message ===
					"You have already requested for security report, We will get back to you shortly"
				) {
					setMessage(t("security-report-page.modal.already-requested.message"))
				} else {
					setMessage(t("security-report-page.modal.success.message"))
				}
				setData(initialData)
			} else {
				setError(response.result.message)
			}
		} catch (err) {
			setError(t("common.messages.internal-server-error"))
		} finally {
			setModal(true)
			setIsSubmitting(false)
		}
	}

	return (
		<form
			onSubmit={handleSubmit}
			className="bg-white shadow-md rounded-lg w-full p-8 flex flex-col gap-8"
		>
			<div className="flex flex-col gap-3">
				<div className="flex flex-col lg:flex-row gap-3">
					<Input
						name="fname"
						value={data.fname}
						onChange={handleDataChange}
						className="w-full h-16"
						placeholder={`${t("common.words.first-name")}*`}
						required
					/>
					<Input
						name="lname"
						value={data.lname}
						onChange={handleDataChange}
						className="w-full h-16"
						placeholder={`${t("common.words.last-name")}*`}
						required
					/>
				</div>

				<Input
					name="email"
					type="email"
					isError={isEmailError}
					value={data.email}
					onChange={handleDataChange}
					className="w-full h-16"
					placeholder={`${t("common.words.email")}*`}
					required
				/>
				<Input
					name="company"
					value={data.company}
					onChange={handleDataChange}
					className="w-full h-16"
					placeholder={`${t("common.words.company")}*`}
					required
				/>
				<Input
					name="job_title"
					value={data.job_title}
					onChange={handleDataChange}
					className="w-full h-16"
					placeholder={`${t("common.words.job-title")}*`}
					required
				/>
			</div>

			<div className="flex flex-col gap-4 items-center">
				<PrimaryBtn
					disabled={isSubmitting}
					type="submit"
					className="w-full py-5"
				>
					{isSubmitting
						? `${t("common.cta.submitting")}...`
						: t("common.cta.submit")}
				</PrimaryBtn>
			</div>
		</form>
	)
}
