import React, { useState } from "react"
import { graphql } from "gatsby"
import { Container, ResponseModal } from "components/library"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Content } from "components/pages/security-report/content"
import { Form } from "components/pages/security-report/form"

const SecurityReport = () => {
	const [modalOpen, setModalOpen] = useState(false)
	const [error, setError] = useState<null | string>(null)
	const { t } = useTranslation()
	const [messsage, setMessage] = useState<string>("")

	return (
		<main>
			<Container className="py-20 grid grid-cols-1 md:grid-cols-2 gap-16 items-center">
				<ResponseModal
					showIllustration={error ? false : true}
					mode={error ? "error" : "success"}
					success={{ title: messsage }}
					error={{
						title: error ?? t("common.messages.internal-server-error"),
					}}
					open={modalOpen}
					setOpen={setModalOpen}
				/>
				<Content />
				<Form
					setMessage={setMessage}
					setError={setError}
					setModal={setModalOpen}
				/>
			</Container>
		</main>
	)
}

export default SecurityReport

// FOR LANGUAGE TRANSLATION
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`
